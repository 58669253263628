import React, { Component } from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import { FormattedMessage } from "gatsby-plugin-intl"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import Hero from "../../components/hero"
import Demo from "../../components/demo"

import One from "../../images/icons/one.svg";
import Two from "../../images/icons/two.svg";
import Three from "../../images/icons/three.svg";
import Four from "../../images/icons/four.svg";

class IndexPage extends Component {
  reveal () {
    const ScrollReveal = require('scrollreveal').default

    ScrollReveal({
      viewFactor: 0.4,
      distance: '100px',
      duration: 1000
    });

    ScrollReveal().reveal('.sr-1', {
      delay: 0
    });

    ScrollReveal().reveal('.sr-2', {
      delay: 100
    });

    ScrollReveal().reveal('.sr-r', {
      origin: 'right'
    });

    ScrollReveal().reveal('.sr-b', {
      origin: 'bottom'
    });
  }

  componentDidMount () {
    if (typeof window !== 'undefined') {
      this.reveal();
    }
  }

  render () {
    return (
      <Layout>
        <SEO title="Valores" description="Valores - expanda seus negócios usando a automação de marketing multicanal baseada em dados e vença a concorrência!" />
        <Hero title="Nossos valores" subtitle="O que nos torna um parceiro confiável?" />

        <div className="container container--pad mar-t-6 mar-b-6">
          <div className="section-h pad-t-3 sr-hidden sr-1">
            <div className="parallax parallax--circle" />
            <h2 className="section-h__2 mar-b-2">
              <FormattedMessage id="values.h_1" />
            </h2>
          </div>
            
          <div className="columns is-variable is-8 mar-t-6">
            <div className="column sr-hidden sr-1">
              <div className="section-h section-h--full section-h--small">
                <One />
                <h2 className="section-h__2 mar-t-2 mar-b-2">
                  <FormattedMessage id="values.h_2" />
                </h2>
                <p className="section-h__3">
                  <FormattedMessage id="values.t_2" />
                </p>
              </div>
            </div>
            <div className="column sr-hidden sr-2">
              <div className="section-h section-h--full section-h--small">
                <Two />
                <h2 className="section-h__2 mar-t-2 mar-b-2">
                  <FormattedMessage id="values.h_3" />
                </h2>
                <p className="section-h__3">
                  <FormattedMessage id="values.t_3" />
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="container container--pad pad-t-3 pad-b-2 mar-t-6 mar-b-6">
          <div className="columns is-variable is-8">
            <div className="column sr-hidden sr-1">
              <div className="section-h section-h--full section-h--small">
                <Three />
                <h2 className="section-h__2 mar-t-2 mar-b-2">
                  <FormattedMessage id="values.h_4" />
                </h2>
                <p className="section-h__3">
                  <FormattedMessage id="values.t_4" />
                </p>
              </div>
            </div>
            <div className="column sr-hidden sr-1">
              <div className="section-h section-h--full section-h--small">
                <Four />
                <h2 className="section-h__2 mar-t-2 mar-b-2">
                  <FormattedMessage id="values.h_5" />
                </h2>
                <p className="section-h__3">
                  <FormattedMessage id="values.t_5" />
                </p>
              </div>
            </div>
          </div>
        </div>

        <Demo props={this.props.data} />
      </Layout>
    )
  }
}

export default IndexPage

export const pageQuery = graphql`
  query {
    photo1: file(relativePath: { eq: "why-us/values/photo-1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 540, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    photo2: file(relativePath: { eq: "why-us/values/photo-2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 540, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    photo3: file(relativePath: { eq: "why-us/values/photo-3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 540, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    photo4: file(relativePath: { eq: "why-us/values/photo-4.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 540, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    esDashboard: file(relativePath: { eq: "expertsender-dashboard.png" }) {
      childImageSharp {
        fluid(maxWidth: 1260, quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
